import React, { Component } from 'react'

class BlogNewsletterForm extends Component {
    render() {
        const url =
            'https://digital.us17.list-manage.com/subscribe/post?u=f1e6d05f59f9c095ac0993ca4&amp;id=5baf7c58ac'
        return (
            <div>
              
            </div>
        )
    }
}

export default BlogNewsletterForm
